import React from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Div100vh from 'react-div-100vh'
import { useTranslation } from 'react-i18next'
import logo from './logo.png';
import './App.css';
import Verify from "./Verify";
import AppStoreLogo from './img/apple_appstore_white.svg'
import PlayStoreLogo from './img/google_playstore_black.png'

function App() {
  const { t } = useTranslation()
  return (
    <Div100vh className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" style={{marginBottom: 10 + 'px'}} />
      </header>
      <Router basename="/">
        <Switch>
          <Route path="/" exact>
            <div>
              {t('frontpage.intro')}
            </div>
          </Route>
        </Switch>
        <Switch>
          <Route path="/verify" component={Verify} />
        </Switch>
      </Router>
      <footer className="App-footer">
        <div>
          <a target="_blank" href="https://apps.apple.com/app/1532620368" rel="noopener noreferrer">
            <img src={AppStoreLogo} />
          </a>
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.martinsen.sharedownership" rel="noopener noreferrer">
            <img src={PlayStoreLogo} style={{ height: '60px' }} />
          </a>
        </div>
      </footer>
    </Div100vh>
  )
}

export default App;
