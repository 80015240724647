import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import i18next from 'i18next'
import en from '../lang/en/en.json'
import fi from '../lang/fi/fi.json'

export const resources = {
  en: {
    translation: en
  },
  fi: {
    translation: fi
  }
} as const

export type Language = keyof typeof resources

const defaultLang: Language = 'fi'

const getLanguage = (): Language => {
  const query = new URLSearchParams(window.location.search)
  const lang = query.get('lang')
  if (lang && Object.keys(resources).includes(lang)) {
    return lang as Language
  }
  return defaultLang
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: defaultLang,
    fallbackLng: defaultLang,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export const changeLanguage = async (lang: Language): Promise<boolean> => {
  try {
    if (i18next.language !== lang) {
      await i18next.changeLanguage(lang)
    }
    return true
  } catch (e) {
    console.log(`Unable to set new language: ${lang}`)
    return false
  }
}

// Instantiate saved language check
;(async () => changeLanguage(getLanguage()))()

export default i18n
