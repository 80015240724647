import React, {useEffect, useState} from 'react';
import * as qs from 'qs';
import ky from 'ky';
import { RouteComponentProps } from 'react-router-dom'
import {Button, Form, Jumbotron, Spinner} from "react-bootstrap";
import {
  BrowserView,
  IOSView,
  AndroidView
} from 'react-device-detect'
import './App.css';
import {useTranslation} from "react-i18next";

interface Verification {
  id: string
  verificationCode: string
  password?: string
}

interface VerificationResponse {
  success: boolean
  needsNewPassword: boolean
}

function App(props: RouteComponentProps) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [verification, setVerification] = useState<Verification | undefined>(undefined)
  const [codeCorrect, setCodeCorrect] = useState<boolean | undefined>(undefined)
  const [needsNewPassword, setNeedsNewPassword] = useState<boolean>(false)

  const verifyInvitation = async (verification: Verification) => {
    try {
      const data = await ky.post(`${process.env.REACT_APP_API_URL}/verify`, {json: verification}).json<VerificationResponse>()
      if (data) {
        setCodeCorrect(data.success)
        setNeedsNewPassword(data.needsNewPassword)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const submitPassword = async (password: string) => {
    setLoading(true)
    await verifyInvitation({ ...verification as Verification, password })
  }

  useEffect(() => {
    const parsed = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    const verificationCode = parsed?.verificationCode as unknown as string
    const id = parsed?.id as unknown as string
    if (verificationCode && id) {
      setVerification({ id, verificationCode })
    } else {
      setLoading(false)
    }
    console.log(verificationCode)
  }, [])

  useEffect(() => {
    if (verification) {
      verifyInvitation(verification)
    }
  }, [verification])
  console.log()

  if (loading) {
    return (
      <>
        <Spinner animation={"border"} />
        <span>{t('verify.loading')}</span>
      </>
    )
  } else if (!verification) {
    return (
      <p>{t('verify.error.missingcode')}</p>
    )
  } else if (codeCorrect) {
    if (needsNewPassword) {
      return (
        <NewPassword submitPassword={submitPassword}/>
      )
    }
    return (
      <>
        <p>
          <p>{t('verify.success')}</p>
        </p>
        <BrowserView>
          <p>
            {t('verify.success.browser')}
          </p>
        </BrowserView>
        <IOSView>
          <p>
            {t('verify.success.ios.1')}<a style={{ color: 'white', fontWeight: 'bold' }} href={process.env.REACT_APP_LINK}>{t('verify.success.ios.2')}</a>{t('verify.success.ios.3')}
          </p>
        </IOSView>
        <AndroidView>
          <p>
            {t('verify.success.android.1')}<a style={{ color: 'white', fontWeight: 'bold' }} href={process.env.REACT_APP_LINK}>{t('verify.success.android.2')}</a>{t('verify.success.android.3')}
          </p>
        </AndroidView>
      </>
    )
  } else if (codeCorrect === false) {
    return (
      <p>{t('verify.error.invalidcode')}</p>
    )
  } else {

    return (
      <>
        <p>{t('verify.error.generic')}</p>
      </>
    )
  }
}


const NewPassword = (props : { submitPassword: (pwd: string) => void }) => {
  const { t } = useTranslation()
  const passwordMinLength = 8
  const [password, setPassword] = useState<string>('')
  const [rePassword, setRePassword] = useState<string>('')

  const doPasswordsMatch = () => {
    return !password || !rePassword || password === rePassword
  }

  const isPasswordLongEnough = () => password?.length > passwordMinLength - 1

  const canSubmit = () => password && rePassword && doPasswordsMatch() && isPasswordLongEnough()

  return (
    <Jumbotron className="App-content">
      <p>{t('verify.password.title')}</p>
      <Form>
        <Form.Group controlId="formBasicPassword">
          <Form.Control type="password" placeholder={t('verify.password.label.password')}
                        onChange={(event) => setPassword(event.target.value)} value={password} minLength={passwordMinLength}/>
          {password && !isPasswordLongEnough() && (
            <Form.Text className="text-danger">
              {t('verify.password.error.length', { passwordMinLength })}
            </Form.Text>
          )}
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Control type="password" placeholder={t('verify.password.label.password2')}
                        onChange={(event) => setRePassword(event.target.value)} value={rePassword} minLength={passwordMinLength} />
          {!doPasswordsMatch() && (
            <Form.Text className="text-danger">
              {t('verify.password.error.mismatch')}
            </Form.Text>
          )}
        </Form.Group>

        <Button variant="info" type="submit" disabled={!canSubmit()} onClick={() => props.submitPassword(password)}>
          {t('verify.password.button')}
        </Button>
      </Form>
    </Jumbotron>
  )
}

export default App;
